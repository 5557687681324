/** Este arquivos existem para evitar um preload de página do gatsby, servem como paginas intermediarias */
import { navigate } from 'gatsby';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import Loader from '../components/loader';

const Div = styled.div`
  display: flex;
  justify-content: center;
  padding: 5rem 0;
`;

export default function FakePage() {
  useEffect(() => {
    navigate('/noticias', { replace: true });
  }, []);

  return (
    <Div>
      <Loader size="150" color="primary" />
    </Div>
  );
}
